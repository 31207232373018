/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import CoreLink from '@core/Link';

import useToggle from '@hooks/useToggle';
import useOutsideClick from '@hooks/useOutsideClick';
import { globalHistory } from '@reach/router';

import LogoSvg from '@vectors/logo.svg';
import MenuSvg from '@vectors/menu.svg';
import CloseSvg from '@vectors/close.svg';
import { cover, layoutWrapper, linkHover } from '@mixins';
import mq, { hover, mqdown } from '@mq';
import scrollTo from '@utils/scrollTo';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import slugify from '@utils/slugify';
import isBrowser from '@utils/isBrowser';
import isMobile from '@utils/isMobile';
import Link from '@core/Link';

const Navigation = ({ isPracticePage }) => {
  const [expanded, toggleExpanded, close] = useToggle(false);

  const {
    contentfulServices: { services },
  } = useStaticQuery(graphql`
    {
      contentfulServices {
        services {
          shortTitle
        }
      }
    }
  `);

  return (
    <>
      {expanded && <ScrollLockStyles />}
      <Container>
        <Home to="/" $expanded={expanded || undefined}>
          <LogoSvg />
        </Home>
        <Toggle onClick={toggleExpanded}>
          {!expanded && <MenuSvg />} {expanded && <CloseSvg />}
        </Toggle>

        {!isPracticePage && (
          <Links $expanded={expanded || undefined}>
            <ScrollToLink
              onClick={() => {
                close();
                navigate('/maximilianstrasse');
              }}
            >
              Maximilianstraße 27
            </ScrollToLink>
            <ScrollToLink
              onClick={() => {
                close();
                navigate('/sternbergstrasse');
              }}
            >
              Sternbergstraße 5
            </ScrollToLink>
          </Links>
        )}
        {isPracticePage && (
          <Links $expanded={expanded || undefined}>
            <ScrollToLink
              onClick={() => {
                if (isPracticePage) {
                  close();
                  scrollTo('#about-us');
                } else {
                  navigate(`/#about-us`);
                }
              }}
            >
              Über uns
            </ScrollToLink>
            <ScrollToLink
              onClick={() => {
                if (isPracticePage) {
                  close();
                  scrollTo('#team');
                } else {
                  navigate(`/#team`);
                }
              }}
            >
              Team
            </ScrollToLink>
            <Dropdown>
              <DropdownHeader
                onClick={() => {
                  if (isPracticePage) {
                    close();
                    scrollTo('#services');
                  } else {
                    navigate(`/#services`);
                  }
                }}
              >
                Leistungen
              </DropdownHeader>
              <DropdownBody>
                {services.map((service, index) => (
                  <DropdownItem
                    key={index}
                    onClick={() => {
                      if (isPracticePage) {
                        close();

                        if (!isMobile) {
                          scrollTo(`#${slugify(service.shortTitle)}`);
                        } else if (isBrowser && window.embla) {
                          scrollTo('#services');

                          setTimeout(() => {
                            window.embla.scrollTo(index);
                          }, 1000);
                        }
                      } else {
                        navigate(`/#${slugify(service.shortTitle)}`);
                      }
                    }}
                  >
                    {service.shortTitle}
                  </DropdownItem>
                ))}
              </DropdownBody>
            </Dropdown>
            <ScrollToLink
              onClick={() => {
                if (isPracticePage) {
                  close();
                  scrollTo('#contact');
                } else {
                  navigate(`/#contact`);
                }
              }}
            >
              Kontakt
            </ScrollToLink>
            <Dropdown>
              <DropdownHeader>Standort</DropdownHeader>
              <DropdownBody>
                <DropdownItem>
                  <Link
                    onClick={() => {
                      close();
                      navigate('/maximilianstrasse');
                    }}
                  >
                    Maximilianstraße
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    onClick={() => {
                      close();
                      navigate('/sternbergstrasse');
                    }}
                  >
                    Sternbergstraße
                  </Link>
                </DropdownItem>
              </DropdownBody>
            </Dropdown>
          </Links>
        )}
      </Container>
    </>
  );
};

const ScrollLockStyles = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

const Container = styled.nav`
  ${layoutWrapper};

  height: var(--navigation-height);

  padding-top: 1rem;
  padding-bottom: 1.25rem;

  ${mq.small} {
    padding-top: 1.25rem;
    padding-bottom: 5.375rem;
  }

  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const Home = styled(CoreLink)`
  width: auto;
  height: 100%;
  z-index: 2;

  ${mqdown.small} {
    height: 100%;
  }

  ${mq.small} {
    padding-bottom: 0.3rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  svg {
    width: 100%;

    ${mqdown.small} {
      height: 100%;
      width: auto;
    }

    path {
      fill: ${props => props.$expanded && 'var(--color-white)'};
    }
  }
`;

const Toggle = styled.div`
  display: flex;
  position: relative;
  z-index: 6;

  ${mq.small} {
    display: none;
  }
`;

const Links = styled.ul`
  font-size: 1.5rem;
  font-weight: 600;
  font-family: var(--font-family-serif);

  ${mq.small} {
    font-size: 1.4rem;
    font-weight: 500;
    flex: 0 0 auto;
    padding-left: 2rem;
  }

  ${mqdown.small} {
    ${cover};
    position: fixed;
    z-index: 5;
    padding-top: var(--navigation-height);
    padding-bottom: var(--navigation-height);
    opacity: ${props => (props.$expanded ? 1 : 0)};
    visibility: ${props => (props.$expanded ? 'visible' : 'hidden')};

    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--color-primary);
  }

  ${mq.small} {
    display: grid;
    grid-auto-flow: column;

    li {
    }
  }
`;

const ScrollToLink = styled.li`
  cursor: pointer;
  ${linkHover};

  ${mqdown.small} {
    line-height: 1.8;
  }

  ${mq.small} {
    padding: 0 1rem;
    position: relative;

    &::after {
      content: '|';
      position: absolute;
      right: -0.125rem;
    }

    &:last-child {
      &::after {
        content: none;
      }
    }
  }
`;

const DropdownHeader = styled(ScrollToLink)`
  ${mqdown.small} {
    text-align: center;
  }
`;

const DropdownBody = styled.ul`
  ${mq.small} {
    position: absolute;
    top: 100%;
    z-index: 2;
    background-color: var(--color-background);
    padding-top: 1rem;
    opacity: 0;
    visibility: hidden;
    padding-bottom: 0.7rem;
  }
`;

const Dropdown = styled.div`
  position: relative;
  z-index: 2;

  ${hover} {
    &:hover {
      & > ${DropdownBody} {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  ${mq.small} {
    &:last-child {
      > li {
        &::after {
          content: none;
        }
      }
    }
  }
`;

const DropdownItem = styled.li`
  font-size: 1.125rem;
  line-height: 1.5;
  padding: 0 1rem;
  cursor: pointer;
  ${linkHover};

  ${mqdown.small} {
    text-align: center;
  }
`;

export default Navigation;
